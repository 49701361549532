@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.4;
  font-family: 'Helvetica Neue', 'sans-serif';
  color: #444;
  background-color: #1f2041;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base{
  li{
    @apply px-4;
    @apply cursor-pointer;
  }
}

.div-content{
  background-repeat: no-repeat;
  background-size: cover;
  height: 220px;
}

.div-content:hover {
  background: linear-gradient(125deg, #6A5ACD 45%, #16172a)!important;
}

#UTM{
  background-position: center;
}

.musk{
  background-size: 320px 220px;
}

#DNOTE{
  background-position: center;
  background-size: cover;
}

.BookIt{
  background-position: center;
  background-size: 420px 220px;
}

@media only screen and (max-width: 600px){
  .BookIt{
    background-size: 480px 220px;
  }
} 

@media only screen and (max-width: 850px){
  #DNOTE{
    background-size: 360px 220px;
  }
} 

@media only screen and (max-width: 613px){
  #DNOTE{
    background-size: 530px 220px;
  }
} 


 @media only screen and (max-width: 650px){
  .musk{
    background-size: 580px 220px;
  }
}  


@media only screen and (max-width: 600px){
  .musk{
    background-size: 490px 220px;
  }
} 

#shell{
  background-size: 460px 220px;
}

#store{
  background-size: cover;
}


@media only screen and (max-width: 418px){
  .fontSmall{
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
  }
} 

@media only screen and (max-width: 418px){
  .musk{
    background-size: 380px 220px;
  }
} 


@media only screen and (max-width: 380px){
  .musk{
    background-size: 350px 220px;
  }
} 



@media only screen and (max-width: 418px){
  #shell{
    background-size: 360px 220px;
  }
}


















