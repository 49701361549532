.logo{
    width: 55px;
    height: 55px;
    border-radius: 10px;
}

.navbar-headings{
    transition: 0.2s all ease-in-out;
}

.navbar-headings:hover {
    transform: scale(1.1);
}
