.waving-hand {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    15% { transform: rotate(14.0deg) } 
    40% { transform: rotate(14.0deg) }
    50% { transform: rotate(-4.0deg) }
    60% { transform: rotate(10.0deg) }
    70% { transform: rotate( 0.0deg) }  
   100% { transform: rotate( 0.0deg) }
}